import { graphql, Link } from "gatsby"
import { Helmet } from "react-helmet"
import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import AnchorLink from "react-anchor-link-smooth-scroll"
import vector from "../images/Source_File_Mark1 1.png"
import "../styles/stylesheet.css"
import Img from "gatsby-image"

const Menu = probs => {
  const [backgroundColor, setbackgroundColor] = useState("transparent")

  const listenScrollEvent = e => {
    if (window.scrollY > 30) {
      setbackgroundColor("white")
    } else {
      setbackgroundColor("transparent")
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent)
  }, [])

  const tabItems = [
    { link: "starters" },
    { link: "mains" },
    { link: "sides" },
    { link: "desserts" },
    { link: "sauces" },
    { link: "drinks" },
  ]

  const products = {
    categories: ["starters", "mains", "sides", "desserts", "sauces", "drinks"],
    productItems: [
      // starter
      {
        productCategory: "starters",
        productName: "SB CHICKEN WINGS",
        description:
          "Chicken wings marinated in spices, then fried to perfection",
        price: "5.95",
        href: "",
        figure: probs.data.chickenWings.childImageSharp.fluid,
      },
      {
        productCategory: "starters",
        productName: "DUCK SAMOSA",
        description:
          "Our crisp signature samosas filled with slow cooked duck leg, potato, dates & cumin",
        price: "5.95",
        href: "",
        figure: probs.data.duckSamosa.childImageSharp.fluid,
      },
      {
        productCategory: "starters",
        productName: "FENNEL PAKORA (v)",
        description:
          "Spiced Indian fritter made with fennel with a hint of aniseed. Served with garlic mayo & date tamarind chutney",
        price: "5.45",
        href: "",
        figure: probs.data.pakora.childImageSharp.fluid,
      },
      {
        productCategory: "starters",
        productName: "WILD MUSHROOM KHICHDI (v)",
        description:
          "Our Indian risotto of spiced basmati rice, wild mushrooms, crispy shallots and soy cured egg yolk",
        price: "5.45",
        href: "",
        figure: probs.data.mushroomKichadi.childImageSharp.fluid,
      },

      // mains
      {
        productCategory: "mains",
        productName: "COURGETTE KOFTA (v)",
        description:
          "Courgette and asparagus mixed with Indian spices and served with yogurt sauce",
        price: "9.75",
        href: "",
        figure: probs.data.kofta.childImageSharp.fluid,
      },
      {
        productCategory: "mains",
        productName: "KERALAN FRIED CHICKEN",
        description:
          "Crispy Fried chicken marinated in traditional Keralan spices and served with pickled cauliflower and mango mayo",
        price: "10.95",
        href: "",
        figure: probs.data.friedChicken.childImageSharp.fluid,
      },
      {
        productCategory: "mains",
        productName: "SB LAMB ROGAN",
        description:
          "Slow cooked leg of lamb, served with our upgraded version of the Rogan sauce",
        price: "11.50",
        href: "",
        figure: probs.data.rogan.childImageSharp.fluid,
      },
      {
        productCategory: "mains",
        productName: "SHAMI KEBAB",
        description:
          "Lamb and date patties served with Jerusalem artichoke puree and garlic raita",
        price: "9.75",
        href: "",
        figure: probs.data.kebab.childImageSharp.fluid,
      },
      {
        productCategory: "mains",
        productName: "CHICKEN MAKHANI (v)",
        description: "Succulent chicken in a spiced tomato and cream sauce ",
        price: "10.95",
        href: "",
        figure: probs.data.makhani.childImageSharp.fluid,
      },

      // sides
      {
        productCategory: "sides",
        productName: "SPICE BREW RAJMA (v)",
        description: "'SB's spiced, warming kidney bean masala",
        price: "3.00",
        href: "",
        figure: probs.data.rajma.childImageSharp.fluid,
      },
      {
        productCategory: "sides",
        productName: "LASSONI SWEET POTATOES (v)",
        description:
          "Our skin-on sweet potato wedges with garlic, chilli, lime & black salt",
        price: "5.95",
        href: "",
        figure: probs.data.lassoni.childImageSharp.fluid,
      },
      {
        productCategory: "sides",
        productName: "SB SHEEP DIP BROCCOLI (v)",
        description:
          "Grilled tender stem broccoli served with our special sheep yogurt dressing and mixed seeds",
        price: "5.95",
        href: "",
        figure: probs.data.broccoli.childImageSharp.fluid,
      },
      {
        productCategory: "sides",
        productName: "SB NAAN (v)",
        description: "Freshly made Indian bread cooked on a traditional tawa",
        price: "3.00",
        href: "",
        figure: probs.data.naan.childImageSharp.fluid,
      },
      {
        productCategory: "sides",
        productName: "SB PARATHA (v)",
        description: "Freshly made wholemeal Indian flatbread with carom seeds",
        price: "3.00",
        href: "",
        figure: probs.data.paratha.childImageSharp.fluid,
      },
      {
        productCategory: "sides",
        productName: "Jeera Rice (v)",
        description: "North Indian Style rice with cumin seeds",
        price: "3.00",
        href: "",
        figure: probs.data.JeeraRice.childImageSharp.fluid,
      },

      // desserts
      {
        productCategory: "desserts",
        productName: "CHAI PANNA COTTA",
        description: "Milk pudding with spiced bramley apple compote",
        price: "6.00",
        href: "",
        figure: probs.data.cotta.childImageSharp.fluid,
      },
      {
        productCategory: "desserts",
        productName: "NANKHATAI BISCUITS",
        description:
          "North Indian shortbread cookies served with home made saffron ice-cream",
        price: "6.50",
        href: "",
        figure: probs.data.biscuits.childImageSharp.fluid,
      },

      // SAUCES & DIPS
      {
        productCategory: "sauces",
        productName: "DATE TAMARIND CHUTNEY",
        description: "",
        price: "1.90",
        href: "",
        figure: probs.data.dateChutney.childImageSharp.fluid,
      },
      {
        productCategory: "sauces",
        productName: "GARLIC MAYO DIP",
        description: "",
        price: "1.90",
        href: "",
        figure: probs.data.garlic.childImageSharp.fluid,
      },
      {
        productCategory: "sauces",
        productName: "MANGO CURRY MAYO DIP",
        description: "",
        price: "1.90",
        href: "",
        figure: probs.data.mangoCurry.childImageSharp.fluid,
      },
      {
        productCategory: "sauces",
        productName: "MINT CHUTNEY",
        description: "",
        price: "1.90",
        href: "",
        figure: probs.data.mintChutney.childImageSharp.fluid,
      },
      {
        productCategory: "sauces",
        productName: "SB RAITA",
        description: "",
        price: "1.90",
        href: "",
        figure: probs.data.raita.childImageSharp.fluid,
      },
      {
        productCategory: "sauces",
        productName: "SB SHEEP YOGURT DRESSING",
        description: "",
        price: "1.90",
        href: "",
        figure: probs.data.yogurt.childImageSharp.fluid,
      },

      // drinks
      {
        productCategory: "drinks",
        productName: "COCA COLA (330ml)",
        description: "",
        price: "2.00",
        href: "",
        figure: probs.data.cocoCola.childImageSharp.fluid,
      },
      {
        productCategory: "drinks",
        productName: "COKE ZERO (330ml)",
        description: "",
        price: "2.00",
        href: "",
        figure: probs.data.cokeZero.childImageSharp.fluid,
      },
      {
        productCategory: "drinks",
        productName: "SPRITE (330ml)",
        description: "",
        price: "2.00",
        href: "",
        figure: probs.data.sprite.childImageSharp.fluid,
      },
    ],
  }

  return (
    <Layout>
      <Helmet>
        <title>Spice Brew | Welcome</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name="description"
          content="Signature dishes exclusively made at Spice Brew by the best chefs from Europe and India."
        />
        <meta name="keywords" content="" />
        <link rel="canonical" href="https://spice-brew.com/menu" />
      </Helmet>

      <section className="flex">
        <div
          className={`flex tab z-10 overflow-x-scroll lg:justify-center sm:px-16 px-6 fixed w-full transition-colors duration-1000 py-4 bg-${backgroundColor}`}
        >
          {tabItems.map((data, index) => {
            return (
              // tabs
              <AnchorLink
                key={index}
                href={`#${data.link}`}
                className="uppercase fill"
              >
                <div
                  aria-hidden="true"
                  className="min-w-max text-navyBlue bg-white text-xs sm:text-sm md:px-5 px-4 md:py-2 py-1  md:mr-6 mr-4 md:mb-3 mb-2 shadow-lg rounded-lg  Moderat-Extended-Regular item"
                >
                  {data.link}
                </div>
              </AnchorLink>
            )
          })}
        </div>
      </section>

      <section className="sm:px-16 sm:py-5 px-6 py-5 relative mt-14">
        <div className="bg-white shadow-2xl rounded h-auto lg:px-14 lg:pb-14 md: p-6 ">
          {products.categories.map((item, index) => {
            return (
              <div className="2xl:px-24 lg:grid justify-center" key={index}>
                {/* anchor link */}

                <div
                  id={item}
                  className="flex items-center capitalize lg:text-2xl md:text-xl text-lg sm:px-4 py-4  text-black Moderat-Extended-bold italic  lg:pt-48 lg:-mt-48 md:pt-40 md:-mt-40 sm:pt-36 sm:-mt-36 pt-36 -mt-36"
                >
                  <span>
                    <img src={vector} alt="" className="w-8" />
                  </span>
                  {item}
                </div>

                {/* product box */}
                <div className="flex justify-center ">
                  <div className="grid lg:grid-cols-2 sm:grid-cols-2 lg:gap-2 md:gap-2 grid-cols-1 gap-3 ">
                    {products.productItems.map((data, index) => {
                      if (item === data.productCategory) {
                        return (
                          <Link
                            className=""
                            to={`http://spice-brew.square.site/`}
                            target="_blank"
                          >
                            <div
                              key={index}
                              className=" lg:w-auto w-auto xl:px-4 lg:px-2 md:px-3 mb-5"
                            >
                              <div className="flex items-center justify-center product-img">
                                <Img
                                  //xl:h-72  lg:w-44 lg:h-56 md:w-40 md:h-52 sm:w-36 sm:h-44 min-h-100px
                                  className="rounded-xl  lg:w-96 lg:h-96 md:w-72 md:h-72 sm:w-56 sm:h-56 w-60 h-52 figure"
                                  fluid={data.figure}
                                />
                              </div>
                              <div className="">
                                <div className="flex justify-between">
                                  <div className="lg:w-72 md:w-52 sm:w-44 w-40">
                                    <p
                                      className=" Moderat-Extended-bold text-black 2xl:text-2xl xl:text-xl lg:text-lg md:text-base text-xs py-1 line-h-12px"
                                      style={{
                                        lineHeight: "20px",
                                        letterSpacing: "-0.02rem",
                                      }}
                                    >
                                      {data.productName}
                                    </p>
                                    <p
                                      style={{
                                        lineHeight: "20px",
                                        letterSpacing: "-0.02rem",
                                      }}
                                      className="Moderat-Condensed-Light lg:text-base md:text-sm text-xs "
                                    >
                                      {data.description}
                                    </p>
                                  </div>

                                  <div className="justify-end min-w-max">
                                    <p className="w-auto 2xl:text-2xl lg:text-xl text-xs md:text-lg 2xl:p-3 lg:p-2 md:p-2 p-2 text-black Moderat-Extended-bold ">
                                      £{" "}
                                      <span className="Moderat-Extended-Regular">
                                        {data.price}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        )
                      }
                      return null
                    })}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </section>
    </Layout>
  )
}

export default Menu
export const pageQuery = graphql`
  query {
    chickenWings: file(relativePath: { eq: "Chicken-Wings.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    duckSamosa: file(relativePath: { eq: "Duck-Samosa-Menu.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pakora: file(relativePath: { eq: "Fennel-Pakora-Menu.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mushroomKichadi: file(relativePath: { eq: "Wild-Mushroom-Khichdi-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    kofta: file(relativePath: { eq: "Courgette-Kofta.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    friedChicken: file(relativePath: { eq: "Keralan-Fried-Chicken-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    rogan: file(relativePath: { eq: "Lamb-Rogan.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    kebab: file(relativePath: { eq: "Shami-Kebab-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    makhani: file(relativePath: { eq: "Chicken-Makhani.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    JeeraRice: file(relativePath: { eq: "Jeera-Rice-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    rajma: file(relativePath: { eq: "SB-Rajma.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lassoni: file(relativePath: { eq: "Lassoni-Sweet-Potato-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    broccoli: file(relativePath: { eq: "Sheep-Dip-Broccoli-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    naan: file(relativePath: { eq: "Naan.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    paratha: file(relativePath: { eq: "Paratha.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    cotta: file(relativePath: { eq: "Chai-Panna-Cota.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    biscuits: file(relativePath: { eq: "Nankhatai-Biscuits-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    dateChutney: file(relativePath: { eq: "image-_5_.webp" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    garlic: file(relativePath: { eq: "image-_4_.webp" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mangoCurry: file(relativePath: { eq: "mango-rosemary-lime-dip.webp" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mintChutney: file(relativePath: { eq: "image-_2_.webp" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    raita: file(relativePath: { eq: "image-_1_.webp" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    yogurt: file(relativePath: { eq: "image.webp" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    cocoCola: file(relativePath: { eq: "drinks_coke.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cokeZero: file(relativePath: { eq: "drinks_zero.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sprite: file(relativePath: { eq: "drinks_sprite.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
